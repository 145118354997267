import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { isEmpty, getOr } from "lodash/fp";

import Defender from "helpers/defender";
import SignOut from "./../../api/auth/sign_out";
import User from "./../../api/users";
import queryString from 'query-string';
import NavbarStandard from './front/NavbarStandard';
import Footer from './front/Footer';
import homeHero from 'assets/img/sp-images/home/hero-doctor-desktop.jpg';
import homeExperts from 'assets/img/sp-images/home/empathy-lg.jpg';
import homeExpertsm from 'assets/img/sp-images/home/empathy-sm.jpg';
import homeCTA from 'assets/img/sp-images/home/quote__icon.png';
import homeRPMS from 'assets/img/sp-images/home/rpms-ehr-logo.png';
import tileDive from 'assets/img/sp-images/home/dive@2x.png';
import tileDiscover from 'assets/img/sp-images/home/discover@2x.png';
import tileDevelop from 'assets/img/sp-images/home/develop@2x.png';
import tileCycle from 'assets/img/sp-images/icons/revenue_cycle.svg';
import tileCoding from 'assets/img/sp-images/icons/financial_planning.svg';
import tileBilling from 'assets/img/sp-images/icons/ensure_compliance.svg';
import operations1 from 'assets/img/sp-images/operations1.png';
import operations2 from 'assets/img/sp-images/operations2.png';
import dash1 from 'assets/img/sp-images/dashboard1.png';
import dash2 from 'assets/img/sp-images/dashboard2.png';
import homeBorder from 'assets/img/sp-images/home/border.svg';
import heroBackground from 'assets/img/sp-images/spearpoint-background.jpg';
import heroImage from 'assets/img/sp-images/spearpoint-dashboard.png';
import serviceIcon1 from 'assets/img/sp-images/icons/monitoring.svg';
import serviceIcon2 from 'assets/img/sp-images/icons/data.svg';
import serviceIcon3 from 'assets/img/sp-images/icons/customize.svg';
import serviceIcon4 from 'assets/img/sp-images/icons/notification.svg';
import serviceIcon5 from 'assets/img/sp-images/icons/reports.svg';
import bg2 from 'assets/img/sp-images/home/bg2.svg';

const SpearPoint = ({ }) => {

	return (
		<div className="spearpoint">
			<NavbarStandard />
			<div className="content content--front">
			<div className='bigscreen__overlay' style={{
					width: '100vw',
					height: '17vh',
					background: '#fff',
					position: 'absolute',
					left: '0',
					border: 'none',
					boxShadow: 'none',
				}}>

				</div>
				<section
					className="hero--spearpoint py-0"
                    style={{ backgroundImage: `url(${heroBackground})` }}
				>

					<div className="container">
						<div className="hero-spearpoint__content">
							<h1 className="hero-spearpoint__title">Spearpoint</h1>
							<h3 className="hero-spearpoint__overline">Precision Monitoring for Your Healthcare Facility</h3>
                            <img src={heroImage} alt="Spearpoint Dashboard" className="hero-spearpoint__img" />
						</div>
					</div>
				</section>


                <section
                    className="operations">
                    <h2 className="section__title">Optimize Your Financial Operations <br />with Real-Time Data</h2>
                    <div className="container container--operations">
                        <div className="operations__left">
                            <div className="subtitle subtitle--home subtitle--operations">
                            <p>Imagine having the ability to monitor your patients' vital signs continuously, gaining valuable insights into their health at all times.</p>
                            <p>Now, consider the possibility of applying the same level of vigilance to your healthcare facility’s financial health. What if you could access crucial insights about your facility’s metrics, data, and trends, all seamlessly integrated in one place?</p>
                            </div>
                        </div>
                        <div className="operations__right">

                            <div className="operations__images">
                                <div className="operations__image operations__image--1" />
                                <img className="operations__image operations__image--2" src={`${operations1}`} />
                                <img className="operations__image operations__image--3" src={`${operations2}`} />
                            </div>
                        </div>
                    </div>
					<div className="container container--operations--dash">
						<div className="operations__left--dash">
							<div className="operations__images--dash">
								<img className="operations__image--dash operations__image--dash1" src={`${dash1}`} />
								<img className="operations__image--dash operations__image--dash2" src={`${dash2}`} />
							</div>
						</div>
							<div className="operations__right--dash">
								<div className="subtitle subtitle--home subtitle--operations">
									<p>
										<span style={{fontWeight: '700', color: '#000'}}>Meet Spearpoint,</span> a unique software solution that connects directly with RPMS to provide a complete overview of your financial operations. 
									</p>
									<p>
										With Spearpoint, you have the capability to continuously monitor, analyze, and improve the financial stability of your facility, ensuring you're always equipped to make informed decisions.
									</p>
								</div>
							</div>
                    </div>
                </section>


				<section className="spearpoint-services">
					<h2 className="section__title">What exactly can Spearpoint do?</h2>
					<div className="container container--services">
						<div className="service__item">
							<img src={serviceIcon1} alt="" className="service__icon" />
							<div>
								<h3 className="service__title">Real-Time Monitoring</h3>
								<p className="service__description">Track financial metrics as they happen, allowing for immediate responses to any financial changes or anomalies.</p>
							</div>
						</div>
						<div className="service__item">
							<img src={serviceIcon2} alt="" className="service__icon" />
							<div>
								<h3 className="service__title">Data-Driven Insights</h3>
								<p className="service__description">Leverage data directly from RPMS to make informed decisions that align with your financial strategies and objectives.</p>
							</div>
						</div>
						<div className="service__item">
							<img src={serviceIcon3} alt="" className="service__icon" />
							<div>
								<h3 className="service__title">Customizable Dashboards</h3>
								<p className="service__description">View your financial data your way with fully customizable dashboards that highlight the information most critical to your operations.</p>
							</div>
						</div>
						<div className="service__item">
							<img src={serviceIcon4} alt="" className="service__icon" />
							<div>
								<h3 className="service__title">Alerts and Notifications</h3>
								<p className="service__description">Set up alerts for key financial indicators to stay ahead of potential issues before they escalate.</p>
							</div>
						</div>
						<div className="service__item">
							<img src={serviceIcon5} alt="" className="service__icon" />
							<div>
								<h3 className="service__title">Comprehensive Reporting</h3>
								<p className="service__description">Generate detailed reports to understand trends, forecast future scenarios, and communicate financial health to stakeholders.</p>
							</div>
						</div>

					</div>
				</section>


				
				<section className="spearpoint-process">
					<div className="container">
						<div className="spearpoint-process__wrapper">
							<h2 className="section__title section__title--scope">Empower Your Financial Decision-Making</h2>
							<div className="subtitle subtitle--home subtitle--process">
							Spearpoint not only monitors but also interprets the vast amounts of financial data generated by your healthcare facility. With these insights, you can:
							</div>
							<div className="tiles tiles--scope">
								<div className="tile__item tile__item--scope tile__item--1">
									<img
										className="tile__img"
										src={tileCycle}
										alt=""
									/>
									<h3 className="tile__title">Improve Revenue Cycle&nbsp;Management</h3>
									<p className="tile__description">Streamline billing and coding processes to reduce errors and maximize revenue</p>
								</div>
								<div className="tile__item tile__item--scope tile__item--2">
									<img
										className="tile__img"
										src={tileCoding}
										alt="" 
									/>
									<h3 className="tile__title">Enhance Financial <br /> Planning</h3>
									<p className="tile__description">Utilize predictive analytics for better budgeting and financial planning</p>
								</div>
								<div className="tile__item tile__item--scope tile__item--3">
									<img
										className="tile__img"
										src={tileBilling}
										alt=""
									/>
									<h3 className="tile__title">Ensure<br />Compliance</h3>
									<p className="tile__description">Maintain and ensure compliance with healthcare financial regulations and standards</p>

								</div>
							</div>
						</div>
					</div>
				</section> 

				<section
					className="cta cta--spearpoint py-0"
					style={{ backgroundColor: 'transparent' }}

					>
					<div
						className="container container--cta"
						style={{ backgroundImage: `url(${heroBackground})` }}
					>
						<div className="cta__left">
							<h2 className="section__title section__title--cta">Connect with Bernie Yazzie Consulting for a Spearpoint Demo</h2>
							<div className="subtitle subtitle--home subtitle--cta">
                            Spearpoint could revolutionize the way you manage the financial health of your healthcare facility. Contact Bernie Yazzie Consulting today for a personalized demonstration and see firsthand the impact of real-time financial monitoring.
							</div>
							<div className='d-flex cta--button'>
								<a href="/schedule" className="btn btn-primary btn--3d btn--rg btn--home">Schedule a Demo</a>
							</div>
						</div>
					</div>
				</section>

			</div>

			<Footer />
		</div>
	);
};

export default SpearPoint;